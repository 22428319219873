body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100dvh;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  color: var(--textColor);
}

input {
  background-color: var(--inputBackground);
  color: var(--inputTextColor);
}

input[type=datetime-local]::-webkit-datetime-edit {
  color: var(--inputTextColor);
}

a:visited, a:link {
  color: inherit;
}